.rag-red {
    /* background-color: red; */
    border: 1px solid red !important;
  }
  
  .rag-maroon {
    /* background-color: red; */
    border: 1px solid #800000 !important;
  }

  .diesel {
    background-color: #FAAB18 ;
  }
  .lsd {
    background-color: #FAAB18 ;
  }
  .ulsd {
    background-color: #FF8500;
  }
  .rds {
    background-color: #D9B300;
  }
  .mgo {
    background-color: #FFE218;
  }
  .rul {
    background-color: #66C4E8;
  }
  .ron91 {
    background-color: #66C4E8;
  }
  .pul {
    background-color: #3385C1;
  }
  .ron95 {
    background-color: #3385C1;
  }
  .jet {
    background-color: #B2CC34;
  }
  .kero {
    background-color: #B2CC34;
  }
  .bo {
    background-color: #FE6DB6;
  }
  .jeta {
    background-color: #E0EBAE;
  }
  .mat-icon {
    vertical-align: middle !important;
  }
  .button-blue {
    color: white !important;
    background-color: #0066B2;
    border:0.5px solid #0066B2!important
  }
  
  .button-white {
    color: #0066B2;
    background-color: white;
    border:0.5px solid #0066B2!important
  }

  .mat-icon {
    vertical-align: top !important;
  }

  .mat-icon-align-middle {
    vertical-align: middle !important;
  }

  .from_date {
    float:left;
    padding-top: 5px;
    margin-bottom: 10px;
    color: #0066B2;
  }