.aggrid-container,
.aggrid-container .aggrid-innercontainer {
  display: block;
  flex-direction: column;
  height: 90%;
}

.aggrid-container .aggrid-innercontainer ag-grid-angular {
  flex-basis: 80%;
  display: flex;
  flex-grow: 1;
}

ag-grid-angular {
  max-height: 100%;
}

.ag-root-wrapper.ag-layout-normal {
  min-width: 40%;
  width: 100%;
  max-width: 100%;
}

.ag-header-cell-label .ag-header-cell-text {
  overflow: hidden;
  text-overflow: clip;
  white-space: pre-wrap;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: black;
  font-family: GothamBold;
  position: relative !important;
}

.ag-header-cell {
  border-style: solid;
  border-width: 0px !important;
  background: white;
}

.ag-header-group-cell:nth-child(n + 1),
.ag-header-cell:nth-child(n + 1) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ag-header-group-cell,
.ag-header-cell:nth-child(0) {
  padding-left: 5px !important;
  padding-right: 0 !important;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: left;
  padding-left: 5px;
}

.ag-header-group-cell.ag-header-group-cell-with-group {
  border: 0px solid #ccc;
}
.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 0px solid #ccc;
  display: table-cell;
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.non-editable {
  background-color: #eee;
  text-align: right;
}

.pd-top {
 padding: 32px;
}

.cursor {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.ag-header-cell-text {
  font-weight: bold;
  color: #112d4e;
}
.mainContainer {
  height: calc(80vh - 2vw);
  //padding: 10px 5px 10px 5px;
  //padding-top: 3%;
}

.vcoContainer {
  height: calc(82vh - 2.8vw);
  padding: 0px 1px 1px 1px;
}

.editable {
  background-color: rgba(32, 34, 33, 0.4);
}

.ag-theme-balham .ag-cell {
  border-right: 1px solid #ccc;
  display: table-cell;
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-save {
  background-color: #27496d;
  font-family: 'Roboto';
  font-weight: 100;
  color: #ffffff;
  border: 0px;
}

.btn-optimize {
  background-color: #f95959;
  font-family: 'Roboto';
  color: #ffffff;
  border: 0px;
}

.case-icons:hover {
  background-color: #e84545 !important;
}

.card {
  font-family: 'roboto';
  font-weight: 300;
}

.card-header {
  background-color: #112d4e;
  color: #f9f7f7;
}

.card-text {
  font-size: 0.8em;
}

.text-center {
  text-align: center;
}

.cursor {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.layout-row {
  display: flex;
  flex-wrap: wrap;
}

.grid-height {
  height: calc(80vh - 1px);
  display: block;
}

.top-bar {
  color: #2d4cfd !important;
  background-color: #f9f7f7;
}

.grid-panel {
  transition: 0.6s;
}

.vco-top-layout {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
}


.ag-bl-normal-east,
.ag-bl-normal,
.ag-bl-normal-center,
.ag-bl-normal-center-row,
.ag-bl-full-height,
.ag-bl-full-height-center,
.ag-pinned-left-cols-viewport,
.ag-pinned-right-cols-viewport,
.ag-body-viewport-wrapper,
.ag-body-viewport {
    height: auto !important;
}

.ag-floating-top {
  position: relative !important;
  top: auto !important;
}

.footer{
  height: 5%;
}

.disabled{
  opacity: 0.5;
  pointer-events: none;

  > * {
    opacity: 0.5;
    pointer-events: none;
  }
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  text-transform: lowercase !important;
}