@import url(../font-awesome/css/fontawesome.min.css);

/*
	Industrious by TEMPLATED
	templated.co @templatedco
	Released for free under the Creative Commons Attribution 3.0 license (templated.co/license)
*/
/* Basic */
@font-face {
  font-family: 'GothamMedium';

  src: url('../fonts/GothamMedium.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamBook';

  src: url('../fonts/GothamBook.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBlack';

  src: url('../fonts/Gotham-Black.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamBold';

  src: url('../fonts/GothamBold.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoRegular';

  src: url('../fonts/Roboto-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

@-ms-viewport {
  width: device-width;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

body {
  -ms-overflow-style: scrollbar;
  font-family: GothamMedium, sans-serif;
  background: #ffffff;
  line-height: 1;
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Typography */
html {
  box-sizing: border-box;
  font-size: 13pt;
}

@media screen and (max-width: 1680px) {
  html {
    font-size: 11pt;
  }
}
@media screen and (max-width: 980px) {
  html {
    font-size: 12pt;
  }
}
@media screen and (max-width: 480px) {
  html {
    font-size: 11pt;
  }
}

a {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
a:hover {
  text-decoration: none;
}

strong,
b {
  font-weight: 900;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 1.2rem 0;
}

header.special {
  text-align: center;
  margin-bottom: 4rem;
}
header.special p {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0 0 1.5rem 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 3rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.25rem;
  line-height: 1.3;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.9rem;
}

h6 {
  font-size: 0.7rem;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 2.75rem;
    line-height: 1.3;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.25rem;
  }
}
sub {
  font-size: 0.8rem;
  position: relative;
  top: 0.5rem;
}

sup {
  font-size: 0.8rem;
  position: relative;
  top: -0.5rem;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1rem 1.5rem;
  overflow-x: auto;
}

hr.major {
  margin: 4rem 0;
}

blockquote {
  border-left: solid 0.5rem;
  font-style: italic;
  margin: 0 0 2rem 0;
  padding: 1rem 0 1rem 2rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
code {
  background: rgba(0, 0, 0, 0.075);
  border-color: rgba(0, 0, 0, 0.25);
}

hr {
  border: 0;
  border-bottom: solid 1px;
  margin: 2rem 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

/* Inner */
.inner {
  margin: 0 auto;
  width: 75rem;
  max-width: calc(100% - 6rem);
}
@media screen and (max-width: 480px) {
  .inner {
    max-width: calc(100% - 3rem);
  }
}

.clear {
  clear: both;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  content: '\f00c';
}

input[type='checkbox'] + label:before {
  border-radius: 4px;
}

input[type='radio'] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #bbbbbb !important;
}

:-moz-placeholder {
  color: #bbbbbb !important;
  opacity: 1;
}

::-moz-placeholder {
  color: #bbbbbb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbbbbb !important;
  opacity: 1;
}

/* List */
ol {
  list-style: decimal;
  margin: 0 0 2rem 0;
  padding-left: 1.25rem;
}
ol li {
  padding-left: 0.25rem;
}

ul {
  list-style: disc;
  margin: 0 0 2rem 0;
  padding-left: 1rem;
}
ul li {
  padding-left: 0.325rem;
}
ul.plain {
  list-style: none;
  padding-left: 0;
}
ul.plain li {
  margin-bottom: 1rem;
}
ul.plain li .icon {
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  margin-right: 1rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  background: rgba(0, 0, 0, 0.5);
}
ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}
ul.alt li:last-child {
  padding-bottom: 0;
}

dl {
  margin: 0 0 2rem 0;
}
dl dt {
  display: block;
  font-weight: 600;
  margin: 0 0 1rem 0;
}
dl dd {
  margin-left: 1.5rem;
}
ul.alt li {
  border-top: solid 1px;
  padding: 0.75rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
/* Table */
.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 2rem 0;
  width: 100%;
}

table td {
  padding: 0.75rem 0.75rem;
}

table.alt {
  border-collapse: separate;
}
table.alt tbody tr td:first-child {
  border-left-width: 1px;
}
table.alt tbody tr:first-child td {
  border-top-width: 1px;
}
table.alt thead {
  border-bottom: 0;
}
table.alt tfoot {
  border-top: 0;
}

table tbody tr {
  border: solid 1px;
  border-left: 0;
  border-right: 0;
  border-color: rgba(0, 0, 0, 0.25);
}
table tbody tr:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.075);
}
table th {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 0.75rem 0.75rem 0.75rem;
  text-align: left;
  color: #555555;
}
table thead {
  border-bottom: solid 2px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
table tfoot {
  border-top: solid 2px;
  border-top-color: rgba(0, 0, 0, 0.25);
}

table.alt tbody tr td {
  border: solid 1px;
  border-left-width: 0;
  border-top-width: 0;
  border-color: rgba(0, 0, 0, 0.25);
}

/* Highlights */
.highlights {
  width: 100%;
  margin: 2.5rem 0;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-align-items: -moz-stretch;
  -webkit-align-items: -webkit-stretch;
  -ms-align-items: -ms-stretch;
  align-items: stretch;
}
.highlights > * {
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  padding: 1.5rem;
  width: calc(33.3333333333% + 1rem);
}

.highlights > :nth-child(-n + 3) {
  padding-top: 0;
}
.highlights > :nth-last-child(-n + 3) {
  padding-bottom: 0;
}
.highlights > :nth-child(3n + 1) {
  padding-left: 0;
}
.highlights > :nth-child(3n) {
  padding-right: 0;
}
.highlights > :nth-child(3n + 1),
.highlights > :nth-child(3n) {
  width: calc(33.3333333333% + -0.5rem);
}
.highlights .content .icon {
  font-size: 5rem;
}
.highlights > div > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 980px) {
  .highlights > * {
    padding: 1rem;
    width: calc(50% + 1rem);
  }
  .highlights > :nth-child(-n + 3) {
    padding-top: 1rem;
  }
  .highlights > :nth-last-child(-n + 3) {
    padding-bottom: 1rem;
  }
  .highlights > :nth-child(3n + 1) {
    padding-left: 1rem;
  }
  .highlights > :nth-child(3n) {
    padding-right: 1rem;
  }
  .highlights > :nth-child(3n + 1),
  .highlights > :nth-child(3n) {
    padding: 1rem;
    width: calc(50% + 1rem);
  }
  .highlights > :nth-child(-n + 2) {
    padding-top: 0;
  }
  .highlights > :nth-last-child(-n + 2) {
    padding-bottom: 0;
  }
  .highlights > :nth-child(2n + 1) {
    padding-left: 0;
  }
  .highlights > :nth-child(2n) {
    padding-right: 0;
  }
  .highlights > :nth-child(2n + 1),
  .highlights > :nth-child(2n) {
    width: calc(50% + 0rem);
  }
  .highlights .content {
    padding: 2rem;
  }
}
@media screen and (max-width: 736px) {
  .highlights > * {
    padding: 1rem;
    width: calc(50% + 1rem);
  }
  .highlights > :nth-child(-n + 3) {
    padding-top: 1rem;
  }
  .highlights > :nth-last-child(-n + 3) {
    padding-bottom: 1rem;
  }
  .highlights > :nth-child(3n + 1) {
    padding-left: 1rem;
  }
  .highlights > :nth-child(3n) {
    padding-right: 1rem;
  }
  .highlights > :nth-child(3n + 1),
  .highlights > :nth-child(3n) {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .highlights > :nth-child(-n + 2) {
    padding-top: 1rem;
  }
  .highlights > :nth-last-child(-n + 2) {
    padding-bottom: 1rem;
  }
  .highlights > :nth-child(2n + 1) {
    padding-left: 1rem;
  }
  .highlights > :nth-child(2n) {
    padding-right: 1rem;
  }
  .highlights > :nth-child(2n + 1),
  .highlights > :nth-child(2n) {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .highlights > :nth-child(-n + 1) {
    padding-top: 0;
  }
  .highlights > :nth-last-child(-n + 1) {
    padding-bottom: 0;
  }
  .highlights > :nth-child(1n + 1) {
    padding-left: 0;
  }
  .highlights > :nth-child(1n) {
    padding-right: 0;
  }
  .highlights > :nth-child(1n + 1),
  .highlights > :nth-child(1n) {
    width: calc(100% + 1rem);
  }
}
.highlights .content {
  border-radius: 4px;
  height: 100%;
  padding: 3rem;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.025);
}
/* Testimonials */
.testimonials {
  margin: 2.5rem 0;
  width: 100%;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-align-items: -moz-stretch;
  -webkit-align-items: -webkit-stretch;
  -ms-align-items: -ms-stretch;
  align-items: stretch;
}
.testimonials > * {
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  padding: 1.5rem;
  width: calc(33.3333333333% + 1rem);
}
.testimonials > :nth-child(-n + 3) {
  padding-top: 0;
}
.testimonials > :nth-last-child(-n + 3) {
  padding-bottom: 0;
}
.testimonials > :nth-child(3n + 1) {
  padding-left: 0;
}
.testimonials > :nth-child(3n) {
  padding-right: 0;
}
.testimonials > :nth-child(3n + 1),
.testimonials > :nth-child(3n) {
  width: calc(33.3333333333% + -0.5rem);
}
.testimonials .content {
  border-radius: 4px;
  height: 100%;
  padding: 3rem;
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.025);
}
.testimonials .content .author {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.testimonials .content .author blockquote {
  margin: 0;
}
.testimonials .content .author .image {
  margin-right: 2rem;
  width: 20%;
}
.testimonials .content .author .image img {
  border-radius: 100%;
  max-width: 100%;
}
.testimonials .content .credit {
  font-size: 0.75rem;
}
.testimonials .content p:last-child {
  margin: 0;
}
.testimonials > div > :last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 980px) {
  .testimonials > * {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .testimonials > :nth-child(-n + 3) {
    padding-top: 1rem;
  }
  .testimonials > :nth-last-child(-n + 3) {
    padding-bottom: 1rem;
  }
  .testimonials > :nth-child(3n + 1) {
    padding-left: 1rem;
  }
  .testimonials > :nth-child(3n) {
    padding-right: 1rem;
  }
  .testimonials > :nth-child(3n + 1),
  .testimonials > :nth-child(3n) {
    padding: 1rem;
    width: calc(50% + 1rem);
  }

  .testimonials > :nth-child(-n + 2) {
    padding-top: 0;
  }
  .testimonials > :nth-last-child(-n + 2) {
    padding-bottom: 0;
  }
  .testimonials > :nth-child(2n + 1) {
    padding-left: 0;
  }
  .testimonials > :nth-child(2n) {
    padding-right: 0;
  }
  .testimonials > :nth-child(2n + 1),
  .testimonials > :nth-child(2n) {
    width: calc(50% + 0rem);
  }
  .testimonials .content {
    padding: 2rem;
  }
}
@media screen and (max-width: 736px) {
  .testimonials > * {
    padding: 1rem;
    width: calc(50% + 1rem);
  }
  .testimonials > :nth-child(-n + 3) {
    padding-top: 1rem;
  }
  .testimonials > :nth-last-child(-n + 3) {
    padding-bottom: 1rem;
  }
  .testimonials > :nth-child(3n + 1) {
    padding-left: 1rem;
  }
  .testimonials > :nth-child(3n) {
    padding-right: 1rem;
  }
  .testimonials > :nth-child(3n + 1),
  .testimonials > :nth-child(3n) {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .testimonials > :nth-child(-n + 2) {
    padding-top: 1rem;
  }
  .testimonials > :nth-last-child(-n + 2) {
    padding-bottom: 1rem;
  }
  .testimonials > :nth-child(2n + 1) {
    padding-left: 1rem;
  }
  .testimonials > :nth-child(2n) {
    padding-right: 1rem;
  }
  .testimonials > :nth-child(2n + 1),
  .testimonials > :nth-child(2n) {
    padding: 1rem;
    width: calc(100% + 2rem);
  }
  .testimonials > :nth-child(-n + 1) {
    padding-top: 0;
  }
  .testimonials > :nth-last-child(-n + 1) {
    padding-bottom: 0;
  }
  .testimonials > :nth-child(1n + 1) {
    padding-left: 0;
  }
  .testimonials > :nth-child(1n) {
    padding-right: 0;
  }
  .testimonials > :nth-child(1n + 1),
  .testimonials > :nth-child(1n) {
    width: calc(100% + 1rem);
  }
}

.testimonials .content .credit strong {
  color: #ce1b28;
}

/* Actions */
ul.actions {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1rem;
  padding-left: 0;
}
ul.actions li {
  padding: 0 0 0 1rem;
  vertical-align: middle;
}
ul.actions.special {
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: calc(100% + 1rem);
}
ul.actions.stacked {
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}
ul.actions.stacked li {
  padding: 1.3rem 0 0 0;
}
ul.actions.stacked li:first-child {
  padding-top: 0;
}
ul.actions.fit {
  width: calc(100% + 1rem);
}
ul.actions.fit li {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}
ul.actions.fit li > * {
  width: 100%;
}
ul.actions.fit.stacked {
  width: 100%;
}
@media screen and (max-width: 480px) {
  ul.actions:not(.fixed) {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
    width: 100% !important;
  }
  ul.actions:not(.fixed) li {
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -moz-flex-shrink: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    padding: 1rem 0 0 0;
    text-align: center;
    width: 100%;
  }
  ul.actions:not(.fixed) li > * {
    width: 100%;
  }
  ul.actions:not(.fixed) li:first-child {
    padding-top: 0;
  }
  ul.actions:not(.fixed) li input[type='submit'],
  ul.actions:not(.fixed) li input[type='reset'],
  ul.actions:not(.fixed) li input[type='button'],
  ul.actions:not(.fixed) li button,
  ul.actions:not(.fixed) li .button {
    width: 100%;
  }
  ul.actions:not(.fixed) li input[type='submit'].icon:before,
  ul.actions:not(.fixed) li input[type='reset'].icon:before,
  ul.actions:not(.fixed) li input[type='button'].icon:before,
  ul.actions:not(.fixed) li button.icon:before,
  ul.actions:not(.fixed) li .button.icon:before {
    margin-left: -0.5rem;
  }
}

/* Grid */
.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 0;
  margin-left: -3rem;
}
.row > * {
  box-sizing: border-box;
  padding: 0 0 0 3rem;
}
.row.gtr-uniform > * > :last-child {
  margin-bottom: 0;
}

.row > .imp {
  order: -1;
}
.row > .col-1 {
  width: 8.3333333333%;
}
.row > .off-1 {
  margin-left: 8.3333333333%;
}
.row > .col-2 {
  width: 16.6666666667%;
}
.row > .off-2 {
  margin-left: 16.6666666667%;
}
.row > .col-3 {
  width: 25%;
}
.row > .off-3 {
  margin-left: 25%;
}
.row > .col-4 {
  width: 33.3333333333%;
}
.row > .off-4 {
  margin-left: 33.3333333333%;
}
.row > .col-5 {
  width: 41.6666666667%;
}
.row > .off-5 {
  margin-left: 41.6666666667%;
}
.row > .col-6 {
  width: 50%;
}
.row > .off-6 {
  margin-left: 50%;
}
.row > .col-7 {
  width: 58.3333333333%;
}
.row > .off-7 {
  margin-left: 58.3333333333%;
}
.row > .col-8 {
  width: 66.6666666667%;
}
.row > .off-8 {
  margin-left: 66.6666666667%;
}
.row > .col-9 {
  width: 75%;
}
.row > .off-9 {
  margin-left: 75%;
}
.row > .col-10 {
  width: 83.3333333333%;
}
.row > .off-10 {
  margin-left: 83.3333333333%;
}
.row > .col-11 {
  width: 91.6666666667%;
}
.row > .off-11 {
  margin-left: 91.6666666667%;
}
.row > .col-12 {
  width: 100%;
}
.row > .off-12 {
  margin-left: 100%;
}
.row.gtr-0 {
  margin-top: 0;
  margin-left: 0rem;
}
.row.gtr-0 > * {
  padding: 0 0 0 0rem;
}
.row.gtr-0.gtr-uniform {
  margin-top: 0rem;
}
.row.gtr-0.gtr-uniform > * {
  padding-top: 0rem;
}
.row.gtr-25 {
  margin-top: 0;
  margin-left: -0.75rem;
}
.row.gtr-25 > * {
  padding: 0 0 0 0.75rem;
}
.row.gtr-25.gtr-uniform {
  margin-top: -0.75rem;
}
.row.gtr-25.gtr-uniform > * {
  padding-top: 0.75rem;
}
.row.gtr-50 {
  margin-top: 0;
  margin-left: -1.5rem;
}
.row.gtr-50 > * {
  padding: 0 0 0 1.5rem;
}
.row.gtr-50.gtr-uniform {
  margin-top: -1.5rem;
}
.row.gtr-50.gtr-uniform > * {
  padding-top: 1.5rem;
}

.row.gtr-uniform {
  margin-top: -3rem;
}
.row.gtr-uniform > * {
  padding-top: 3rem;
}
.row.gtr-150 {
  margin-top: 0;
  margin-left: -4.5rem;
}
.row.gtr-150 > * {
  padding: 0 0 0 4.5rem;
}
.row.gtr-150.gtr-uniform {
  margin-top: -4.5rem;
}
.row.gtr-150.gtr-uniform > * {
  padding-top: 4.5rem;
}
.row.gtr-200 {
  margin-top: 0;
  margin-left: -6rem;
}
.row.gtr-200 > * {
  padding: 0 0 0 6rem;
}
.row.gtr-200.gtr-uniform {
  margin-top: -6rem;
}
.row.gtr-200.gtr-uniform > * {
  padding-top: 6rem;
}

@media screen and (max-width: 1680px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: -3rem;
  }
  .row > * {
    box-sizing: border-box;
    padding: 0 0 0 3rem;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row > .imp-xlarge {
    order: -1;
  }
  .row > .col-1-xlarge {
    width: 8.3333333333%;
  }
  .row > .off-1-xlarge {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-xlarge {
    width: 16.6666666667%;
  }
  .row > .off-2-xlarge {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-xlarge {
    width: 25%;
  }
  .row > .off-3-xlarge {
    margin-left: 25%;
  }
  .row > .col-4-xlarge {
    width: 33.3333333333%;
  }
  .row > .off-4-xlarge {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-xlarge {
    width: 41.6666666667%;
  }
  .row > .off-5-xlarge {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-xlarge {
    width: 50%;
  }
  .row > .off-6-xlarge {
    margin-left: 50%;
  }
  .row > .col-7-xlarge {
    width: 58.3333333333%;
  }
  .row > .off-7-xlarge {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-xlarge {
    width: 66.6666666667%;
  }
  .row > .off-8-xlarge {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-xlarge {
    width: 75%;
  }
  .row > .off-9-xlarge {
    margin-left: 75%;
  }
  .row > .col-10-xlarge {
    width: 83.3333333333%;
  }
  .row > .off-10-xlarge {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-xlarge {
    width: 91.6666666667%;
  }
  .row > .off-11-xlarge {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-xlarge {
    width: 100%;
  }
  .row > .off-12-xlarge {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.75rem;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.75rem;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.75rem;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.75rem;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -1.5rem;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 1.5rem;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -1.5rem;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 1.5rem;
  }
  .row.gtr-uniform {
    margin-top: -3rem;
  }
  .row.gtr-uniform > * {
    padding-top: 3rem;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -4.5rem;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 4.5rem;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -4.5rem;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 4.5rem;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -6rem;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 6rem;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -6rem;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 1280px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: -1.5rem;
  }
  .row > * {
    box-sizing: border-box;
    padding: 0 0 0 1.5rem;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row > .imp-large {
    order: -1;
  }
  .row > .col-1-large {
    width: 8.3333333333%;
  }
  .row > .off-1-large {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-large {
    width: 16.6666666667%;
  }
  .row > .off-2-large {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-large {
    width: 25%;
  }
  .row > .off-3-large {
    margin-left: 25%;
  }
  .row > .col-4-large {
    width: 33.3333333333%;
  }
  .row > .off-4-large {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-large {
    width: 41.6666666667%;
  }
  .row > .off-5-large {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-large {
    width: 50%;
  }
  .row > .off-6-large {
    margin-left: 50%;
  }
  .row > .col-7-large {
    width: 58.3333333333%;
  }
  .row > .off-7-large {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-large {
    width: 66.6666666667%;
  }
  .row > .off-8-large {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-large {
    width: 75%;
  }
  .row > .off-9-large {
    margin-left: 75%;
  }
  .row > .col-10-large {
    width: 83.3333333333%;
  }
  .row > .off-10-large {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-large {
    width: 91.6666666667%;
  }
  .row > .off-11-large {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-large {
    width: 100%;
  }
  .row > .off-12-large {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }

  .row.gtr-uniform {
    margin-top: -1.5rem;
  }
  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 980px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: -1.5rem;
  }
  .row > * {
    box-sizing: border-box;
    padding: 0 0 0 1.5rem;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row > .imp-medium {
    order: -1;
  }
  .row > .col-1-medium {
    width: 8.3333333333%;
  }
  .row > .off-1-medium {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-medium {
    width: 16.6666666667%;
  }
  .row > .off-2-medium {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-medium {
    width: 25%;
  }
  .row > .off-3-medium {
    margin-left: 25%;
  }
  .row > .col-4-medium {
    width: 33.3333333333%;
  }
  .row > .off-4-medium {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-medium {
    width: 41.6666666667%;
  }
  .row > .off-5-medium {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-medium {
    width: 50%;
  }
  .row > .off-6-medium {
    margin-left: 50%;
  }
  .row > .col-7-medium {
    width: 58.3333333333%;
  }
  .row > .off-7-medium {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-medium {
    width: 66.6666666667%;
  }
  .row > .off-8-medium {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-medium {
    width: 75%;
  }
  .row > .off-9-medium {
    margin-left: 75%;
  }
  .row > .col-10-medium {
    width: 83.3333333333%;
  }
  .row > .off-10-medium {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-medium {
    width: 91.6666666667%;
  }
  .row > .off-11-medium {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-medium {
    width: 100%;
  }
  .row > .off-12-medium {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.375rem;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.375rem;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.375rem;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.375rem;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.75rem;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.75rem;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.75rem;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.75rem;
  }
  .row.gtr-uniform {
    margin-top: -1.5rem;
  }
  .row.gtr-uniform > * {
    padding-top: 1.5rem;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -2.25rem;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 2.25rem;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -2.25rem;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 2.25rem;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -3rem;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 3rem;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -3rem;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 736px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: -1.25rem;
  }
  .row > * {
    box-sizing: border-box;
    padding: 0 0 0 1.25rem;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row > .imp-small {
    order: -1;
  }
  .row > .col-1-small {
    width: 8.3333333333%;
  }
  .row > .off-1-small {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-small {
    width: 16.6666666667%;
  }
  .row > .off-2-small {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-small {
    width: 25%;
  }
  .row > .off-3-small {
    margin-left: 25%;
  }
  .row > .col-4-small {
    width: 33.3333333333%;
  }
  .row > .off-4-small {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-small {
    width: 41.6666666667%;
  }
  .row > .off-5-small {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-small {
    width: 50%;
  }
  .row > .off-6-small {
    margin-left: 50%;
  }
  .row > .col-7-small {
    width: 58.3333333333%;
  }
  .row > .off-7-small {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-small {
    width: 66.6666666667%;
  }
  .row > .off-8-small {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-small {
    width: 75%;
  }
  .row > .off-9-small {
    margin-left: 75%;
  }
  .row > .col-10-small {
    width: 83.3333333333%;
  }
  .row > .off-10-small {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-small {
    width: 91.6666666667%;
  }
  .row > .off-11-small {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-small {
    width: 100%;
  }
  .row > .off-12-small {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125rem;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.3125rem;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125rem;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125rem;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625rem;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.625rem;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.625rem;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625rem;
  }
  .row.gtr-uniform {
    margin-top: -1.25rem;
  }
  .row.gtr-uniform > * {
    padding-top: 1.25rem;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875rem;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 1.875rem;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -1.875rem;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875rem;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5rem;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 2.5rem;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -2.5rem;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5rem;
  }
}
@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 0;
    margin-left: -1.25rem;
  }
  .row > * {
    box-sizing: border-box;
    padding: 0 0 0 1.25rem;
  }
  .row.gtr-uniform > * > :last-child {
    margin-bottom: 0;
  }

  .row > .imp-xsmall {
    order: -1;
  }
  .row > .col-1-xsmall {
    width: 8.3333333333%;
  }
  .row > .off-1-xsmall {
    margin-left: 8.3333333333%;
  }
  .row > .col-2-xsmall {
    width: 16.6666666667%;
  }
  .row > .off-2-xsmall {
    margin-left: 16.6666666667%;
  }
  .row > .col-3-xsmall {
    width: 25%;
  }
  .row > .off-3-xsmall {
    margin-left: 25%;
  }
  .row > .col-4-xsmall {
    width: 33.3333333333%;
  }
  .row > .off-4-xsmall {
    margin-left: 33.3333333333%;
  }
  .row > .col-5-xsmall {
    width: 41.6666666667%;
  }
  .row > .off-5-xsmall {
    margin-left: 41.6666666667%;
  }
  .row > .col-6-xsmall {
    width: 50%;
  }
  .row > .off-6-xsmall {
    margin-left: 50%;
  }
  .row > .col-7-xsmall {
    width: 58.3333333333%;
  }
  .row > .off-7-xsmall {
    margin-left: 58.3333333333%;
  }
  .row > .col-8-xsmall {
    width: 66.6666666667%;
  }
  .row > .off-8-xsmall {
    margin-left: 66.6666666667%;
  }
  .row > .col-9-xsmall {
    width: 75%;
  }
  .row > .off-9-xsmall {
    margin-left: 75%;
  }
  .row > .col-10-xsmall {
    width: 83.3333333333%;
  }
  .row > .off-10-xsmall {
    margin-left: 83.3333333333%;
  }
  .row > .col-11-xsmall {
    width: 91.6666666667%;
  }
  .row > .off-11-xsmall {
    margin-left: 91.6666666667%;
  }
  .row > .col-12-xsmall {
    width: 100%;
  }
  .row > .off-12-xsmall {
    margin-left: 100%;
  }
  .row.gtr-0 {
    margin-top: 0;
    margin-left: 0rem;
  }
  .row.gtr-0 > * {
    padding: 0 0 0 0rem;
  }
  .row.gtr-0.gtr-uniform {
    margin-top: 0rem;
  }
  .row.gtr-0.gtr-uniform > * {
    padding-top: 0rem;
  }
  .row.gtr-25 {
    margin-top: 0;
    margin-left: -0.3125rem;
  }
  .row.gtr-25 > * {
    padding: 0 0 0 0.3125rem;
  }
  .row.gtr-25.gtr-uniform {
    margin-top: -0.3125rem;
  }
  .row.gtr-25.gtr-uniform > * {
    padding-top: 0.3125rem;
  }
  .row.gtr-50 {
    margin-top: 0;
    margin-left: -0.625rem;
  }
  .row.gtr-50 > * {
    padding: 0 0 0 0.625rem;
  }
  .row.gtr-50.gtr-uniform {
    margin-top: -0.625rem;
  }
  .row.gtr-50.gtr-uniform > * {
    padding-top: 0.625rem;
  }
  .row.gtr-uniform {
    margin-top: -1.25rem;
  }
  .row.gtr-uniform > * {
    padding-top: 1.25rem;
  }
  .row.gtr-150 {
    margin-top: 0;
    margin-left: -1.875rem;
  }
  .row.gtr-150 > * {
    padding: 0 0 0 1.875rem;
  }
  .row.gtr-150.gtr-uniform {
    margin-top: -1.875rem;
  }
  .row.gtr-150.gtr-uniform > * {
    padding-top: 1.875rem;
  }
  .row.gtr-200 {
    margin-top: 0;
    margin-left: -2.5rem;
  }
  .row.gtr-200 > * {
    padding: 0 0 0 2.5rem;
  }
  .row.gtr-200.gtr-uniform {
    margin-top: -2.5rem;
  }
  .row.gtr-200.gtr-uniform > * {
    padding-top: 2.5rem;
  }
}
/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.icon > .label {
  display: none;
}

/* Icons */
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.icons li {
  display: inline-block;
  padding: 0 1rem 0 0;
}
ul.icons li:last-child {
  padding-right: 0;
}
ul.icons li .icon:before {
  font-size: 2rem;
}

/* Image */
.image {
  border-radius: 4px;
  border: 0;
  display: inline-block;
  position: relative;
}
.image img {
  border-radius: 4px;
  display: block;
}
.image.left,
.image.right {
  max-width: 40%;
}
.image.left img,
.image.right img {
  width: 100%;
}
.image.left {
  float: left;
  margin: 0 2rem 2rem 0;
  top: 0.25rem;
}
.image.right {
  float: right;
  margin: 0 0 2rem 2rem;
  top: 0.25rem;
}
.image.fit {
  display: block;
  margin: 0 0 2rem 0;
  width: 100%;
}
.image.fit img {
  width: 100%;
}
.image.main {
  display: block;
  margin: 0 0 3rem 0;
  width: 100%;
}
.image.main img {
  width: 100%;
}

/* Wrapper */
.wrapper {
  padding: 8rem 0 6rem 0;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1280px) {
  .wrapper {
    padding: 4rem 0 2rem 0;
  }
}
@media screen and (max-width: 736px) {
  .wrapper {
    padding: 3rem 0 1rem 0;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 2rem 0 0.1rem 0;
  }
}
.text-highlight {
  color: red !important;
}

.text-highlight::after {
  content: '*';
  position: absolute;
  right: 10px;
  top: 0px;
  color: red;
  font-size: x-large;
}

.mat-select-panel-wrap {
  flex-basis: 90%;
  padding-left: 22px !important;
  padding-top: 31px !important;
}

.mat-progress-bar .mat-progress-bar-element,
.mat-progress-bar .mat-progress-bar-fill::after {
  height: 5px !important;
  position: absolute;
  width: 100%;
}

.mat-progress-bar-fill::after {
  background-color: #5271e2 !important;
}

.homebgImage:after {
  content: url(/app/assets/images/default.jpg)
    url(/app/assets/images/Pascagoula.jpg) url(/app/assets/images/ElSegundo.jpg)
    url(/app/assets/images/Richmond_01.jpg);
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#app-panel-component {
  display: table-cell;
}

.main-wrapper .headerComponent {
  display: none;
}

.main-wrapper .dock-topmost {
  display: none;
}

.main-wrapper .dock-top .append-logo > div[dock-top] {
  margin-left: 10px;
}

.main-wrapper .dock-top .append-logo .logo-content {
  display: flex !important;
}

.main-wrapper .mainContainer {
  height: calc(100vh - 25px) !important;
  padding: 0px 10px 10px 4px;
}

/* .main-wrapper .footerComponent{
  display: none;
} */
.main-wrapper .minDataNavigatorComponent {
  height: calc(100vh - 40px) !important;
}
.main-wrapper .dock-top .img-collapse {
  display: none;
}

.main-wrapper .dock-top .img-expand {
  display: flex;
}

.main-wrapper .dock-top .chart {
  display: flex !important;
}

.main-wrapper .dock-top .chart .fa {
  font-family: FontAwesome, sans-serif !important;
  font-size: 36px !important;
}

.main-wrapper .dock-top .expand-menu {
  display: flex;
}

.main-wrapper .dock-top .img-expand:hover + ul.expand-options {
  display: block !important;
}

.main-wrapper .dock-top ul.expand-options:hover {
  display: block !important;
}

.main-wrapper.hover-top-most .dock-topmost {
  display: block;
  width: 99vw;
  height: 48px;
  top: 5px;
  position: absolute;
  z-index: 9999;
  background-color: white;
}

.main-wrapper .notification-pop-up {
  top: 10px !important;
}

.logo {
  background-image: url('../images/chevronLogo.jpg');
  background-size: contain;
  height: 45px;
  width: 50px;
  display: block;
  float: left;
  background-repeat: no-repeat;
}

.menu {
  display: block;
  float: left;
  padding-top: 10px;
}

.logoTitle {
  color: #122a68;
  font-size: 26px;
  font-weight: 900;
  padding-left: 5px;
  padding-right: 5px;
  font-family: GothamBlack, sans-serif;
}

.loginInfo {
  float: right;
}
.title {
  height: 24px;
  width: 62px;
  color: #132a67;
  font-family: GothamBlack, sans-serif;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.notification-pop-up {
  top: 115px !important;
}

.liftDeliveriesComponent .mat-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 140px !important;
}

.mat-badge-content {
  color: #fff;
  background: #1ec10a !important;
}
