
.modal-body > * {
  .form-control-sm {
    font-size: 0.75rem;
  }

  .col-form-label-sm {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .form-check-label::before {
    content: "";
  }

  tr {
    background-color: #ffffff;
  }

  tr .form-group {
    margin-bottom: 5px;
  }

  .form-group.is-invalid .invalid-feedback {
    display: block;
  }
}

.modal-dialog {
  max-width: 90% !important;
  margin: 1.75rem auto;
  height: 100%;
}

.search-nomination-link {
  position: absolute;
  right: 20px;
  top: 5px;
  color: skyblue;
  cursor: pointer;
}

.show-nom-search {
  display: block;
}

.hide-nom-search {
  display: none;
}
