/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

/*@import '../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './app/app.component.scss';
.ag-theme-balham {
  @include ag-theme-balham();
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}*/
@import './assets/styles/main.css';
@import '../node_modules/ag-grid-community/src/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss';
@import './app/app.component.scss';
@import './app/cca/cargo-schedules-worksheet/cargo-schedules-worksheet.scss';
@import './app/refinery-exports/exports-worksheet/exports-worksheet.component.scss';
@import './app/refinery-exports/workflow-comments/workflow-comments.component.scss';
@import './app/nominations-manager/multi-nominations-view/multi-nominations-view.component.scss';

.ag-theme-balham {
  @include ag-theme-balham();
}

html {
  font-size: 14px;
}
body {
  margin: 0;
  padding: 0;
  font-family: GothamMedium;
}
* {
  font-family: GothamMedium;
}
.font-bold {
  font-family: GothamBold;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

/* .ag-theme-balham .ag-tooltip {
  border: none;
  color: red;
  background-color: white;
} */
.ag-theme-balham .ag-cell {
  border-right: 1px solid #ccc;
  display: table-cell;
  vertical-align: middle;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/*modal */
.modal.left .modal-dialog {
  position: fixed;
  right: 0;
  margin: auto;
  width: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

body {
  background-color: white !important;
  height: 100%;
}

.card-title {
  color: #112d4e !important;
}

.card {
  background-color: #f9f7f7 !important;
}

.card-footer {
  /*background-color: #3f72af !important;*/
  background-color: #3f72af !important;
  color: #f9f7f7 !important;
}

.card-footer .text-muted {
  color: #f9f7f7 !important;
}

.card-info {
  padding-bottom: 16px;
}

.card-info small {
  display: block;
}

.text-dark {
  color: #112d4e !important;
}

.modal-header {
  background-color: white;
  color: #112d4e !important;
}

.modal-footer {
  background-color: #f9f7f7;
}

.btn-primary {
  background-color: #3f72af !important;
}

.btn-secondary {
  background-color: #112d4e;
}

.group-header {
  color: black;
}

.ag-header-group-cell-with-group {
  background-color: #3f72af;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: none !important;
  max-width: none !important;
}

.mat-icon-button{
  justify-self: end !important;
}

/* Datepicker */
.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}
.ag-theme-balham .ag-selection-checkbox {
  margin-left: 10px !important;
}