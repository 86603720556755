.ag-header-group-cell-label {
    justify-content: center !important;
    color: #FFFFFF;
 }
.rag-red {
  /* background-color: red; */
  border: 3px solid red !important;
}
.rag-yellow {
  border: 3px solid lightyellow !important;
  background-color: yellow;
}
.custom-text-right {
  background-color: #eee;
  text-align: right;
  padding-right: 50px; // just to make sure that text doesn't go hit itself to next column border
}
.container-border{
  border: 1px solid #ccc;
  border-radius: 15px;  
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  }
  .div-padding-top{
    padding-top: 3px;
  }

  .diesel {
    background-color: #FAAB18 ;
  }
  .lsd {
    background-color: #FAAB18 ;
  }
  .ulsd {
    background-color: #FF8500;
  }
  .rds {
    background-color: #D9B300;
  }
  .mgo {
    background-color: #FFE218;
  }
  .rul {
    background-color: #66C4E8;
  }
  .ron91 {
    background-color: #66C4E8;
  }
  .pul {
    background-color: #3385C1;
  }
  .ron95 {
    background-color: #3385C1;
  }
  .jet {
    background-color: #B2CC34;
  }
  .kero {
    background-color: #B2CC34;
  }
  .bo {
    background-color: #FE6DB6;
  }
  .jeta {
    background-color: #E0EBAE;
  }
  .cell-white {
    background-color: white;
  }
  .cell-publish {
    background-color: white;
    color: green;
  }
  .cell-completed {
    background-color: grey;
  }
  .cell-red {
    /* background-color: red; */
    border: 3px solid red !important;
  }

.badgeLabel {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 14px;
    font-weight: bold;
    color:#0275d8;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-toggle-2 {
    display: inline-block;
    color: #0275d8;
    background-color: #ffffff;
    border-color: grey;
    font-size: 14px;
    font-weight: bold;
    width: 200px;
    text-align: left;
}

.mat-icon {
  vertical-align: top !important;
}

.button-white {
  color: #0066B2;
  background-color: white;
  border:0.5px solid #0066B2!important
}


.modal.left .modal-dialog {
  position: fixed;
  right: 0;
  margin: auto;
  width: 800px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}
.modal-dialog {
  max-width: 800px!important;
  margin: 1.75rem auto;
}

h6 {
  font-size: 14px !important;
  color: #0860b8 !important;
  font-family: 'GOTHAMBOLD' !important;
  text-transform: lowercase !important;
}

div.info {
  display: inline-block;
  width: 190px;
}

select{
  width:100px;
  height: 30px;
  border: 1px solid #535d66;
  color: #0860b8;
}

.selectApproval{
  width:200px;
  height: 35px;
  border: 1px solid #535d66;
  color: #0860b8;
}

.deliverycolor{  
  border: 1px solid #535d66;
  color: #0860b8;
}

.laycanvalidation{  
  border: 1px solid red !important;
  color: red !important;
}

.mat-mdc-outlined-button:not(:disabled) {
   color: #0860b8 !important;
}

.bold{
  font:bolder !important;
  width: 165px;
}